<template>
  <section id="dashboard-ecommerce">
    <b-card>
      <b-row>
        <b-col>
          <h1>
            Nova Empresa
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="9"
          class="mt-2"
        >
          <b-form-group
            label="Nome"
            label-for="Nome"
          >
            <b-form-input
              id="Nome"
              v-model="nomeEmpresa"
              placeholder="Digite o nome"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="3"
          class="mt-2"
        >
          <b-form-group
            label="Status"
            label-for="selecionetipo"
          >
            <v-select
              v-model="categoriaTipo"
              label="text"
              :options="opcoesTipo"
            />
          </b-form-group>

        </b-col>
        <b-col md="6">
          <b-form-group
            label="E-mail"
            label-for="E-mail"
          >
            <b-form-input
              id="E-mail"
              v-model="emailEmpresa"
              placeholder="Digite o email"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Telefone"
            label-for="Telefone"
          >
            <b-form-input
              id="Telefone"
              v-model="telefoneEmpresa"
              placeholder="Digite o telefone"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <label for="textarea-default">Descrição</label>
          <b-form-textarea
            id="textarea-default"
            v-model="descEmpresa"
            placeholder="Descrição"
            rows="3"
          />

        </b-col>
        <b-col md="12">
          <b-form-group
            label="Instagram"
            label-for="Instragram"
          >
            <b-form-input
              id="Instagram"
              v-model="instaEmpresa"
              placeholder="Digite o Instagram"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Facebook"
            label-for="Facebook"
          >
            <b-form-input
              id="Facebook"
              v-model="faceEmpresa"
              placeholder="Digite o facebook"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Cep"
            label-for="Cep"
          >
            <b-form-input
              id="Cep"
              v-model="cepEmpresa"
              placeholder="Digite o Cep"
            />
          </b-form-group>
        </b-col>
        <b-col md="9">
          <b-form-group
            label="Endereço"
            label-for="Logradouro"
          >
            <b-form-input
              id="Logradouro"
              v-model="enderecoEmpresa"
              placeholder="Digite o Edereço"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Nº"
            label-for="Nº"
          >
            <b-form-input
              id="Nº"
              v-model="numeroEmpresa"
              placeholder="Digite o Nº"
            />
          </b-form-group>
        </b-col>
        <b-col md="9">
          <b-form-group
            label="Bairro"
            label-for="Bairro"
          >
            <b-form-input
              id="Bairro"
              v-model="bairroEmpresa"
              placeholder="Digite o Bairro"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Selecione a Empresa"
            label-for="selecionetipo"
          >
            <v-select
              v-model="empresa"
              label="title"
            />
          </b-form-group>

        </b-col>
        <b-col md="6">
          <b-form-group
            label="Selecione a Cidade"
            label-for="selecionetipo"
          >
            <v-select
              v-model="cidadeEmpresa"
              label="title"
            />
          </b-form-group>

        </b-col>
      </b-row>
      <b-row />
      <b-row>
        <b-col>
          <b-form-file
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col />
      </b-row>
      <b-row>
        <b-col class="mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="ZerarCampos"
          >
            Limpar
          </b-button>
        </b-col>
        <b-col class="mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="SalvarDados"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>

    </b-card>
  </section>
</template>
<script>
export default {
  name: 'Novoempresas',
}
</script>

<style scoped>

</style>
